import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`About this tutorial`}</h2>
    <p>{`In this example, we'll configure Kubernetes `}<a parentName="p" {...{
        "href": "https://cert-manager.io/"
      }}>{`cert-manager`}</a>{` to get a certificate from an internal ACME server,
using cert-manager's `}<a parentName="p" {...{
        "href": "https://cert-manager.io/docs/configuration/acme/"
      }}>{`ACME issuer`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`Estimated effort: Reading time ~4 mins, Lab time ~20 to 60 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open source -`}</strong>{` You have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` ACME instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/acme-basics"
        }}>{`our ACME server tutorial`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` this tutorial assumes you have `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/getting-started"
        }}>{`created a hosted or linked authority`}</a>{` and are running a local `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/registration-authorities/acme-for-certificate-manager"
        }}>{`ACME Registration Authority`}</a>{`. `}</li>
      <li parentName="ul">{`You'll need the root certificate PEM file for your CA.`}</li>
    </ul>
    <h3>{`0. Before you begin`}</h3>
    <p>{`This example uses the ACME `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` challenge type, with `}<a parentName="p" {...{
        "href": "https://console.cloud.google.com/net-services/dns/"
      }}>{`Google Cloud DNS`}</a>{`.
We'll create a service account on Google Cloud that cert-manager will use to solve DNS challenges.
For other DNS providers, or other ACME challenge types, you'll need to change the challenge solver settings below.`}</p>
    <h3>{`1. Create a Kubernetes cluster`}</h3>
    <p>{`For this tutorial, I created a Google Compute Engine VM running a `}<a parentName="p" {...{
        "href": "https://kind.sigs.k8s.io/"
      }}>{`kind`}</a>{` cluster.
I'm using kind for testing, but pretty much any Kubernetes cluster will do.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kind cluster create
`}</code></pre>
    <h3>{`2. Set up cert-manager to trust your internal CA`}</h3>
    <p>{`Let's install `}<a parentName="p" {...{
        "href": "https://cert-manager.io/"
      }}>{`Kubernetes cert-manager`}</a>{` and patch it so that it will trust your internal ACME CA.`}</p>
    <p>{`First, install cert-manager:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl apply --validate=false -f https://github.com/jetstack/cert-manager/releases/download/v1.0.4/cert-manager.yaml
`}</code></pre>
    <p>{`Next, create a `}<inlineCode parentName="p">{`ConfigMap`}</inlineCode>{` that contains your ACME server's CA certificate.
To find your certificate's PEM file, select your CA in the `}<a parentName="p" {...{
        "href": "https://console.cloud.google.com/security/cas/manage"
      }}>{`Google Cloud CAS Console`}</a>{`, and view your CA certificate under the Actions menu.`}</p>
    <p>{`Create a file called `}<inlineCode parentName="p">{`internal-ca.yaml`}</inlineCode>{`, replacing the certificate shown here with your own:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: v1
data:
  internal-ca.pem: |
    -----BEGIN CERTIFICATE-----
    [REPLACE with your CA certificate]
    -----END CERTIFICATE-----
kind: ConfigMap
metadata:
  name: ca-pemstore
  namespace: cert-manager
  resourceVersion: "9978"
  selfLink: /api/v1/namespaces/cert-manager/configmaps/ca-pemstore
---
`}</code></pre>
    <p>{`Apply it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl apply -f internal-ca.yaml
`}</code></pre>
    <p>{`To inject this `}<inlineCode parentName="p">{`ConfigMap`}</inlineCode>{` into cert-manager, we need to patch the `}<inlineCode parentName="p">{`cert-manager`}</inlineCode>{` Deployment to add the CA certificate as a container volume mount.`}</p>
    <p>{`Create a file called `}<inlineCode parentName="p">{`cm-ca-patch.yaml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`spec:
  template:
    spec:
      containers:
      - args:
        - --v=2
        - --cluster-resource-namespace=$(POD_NAMESPACE)
        - --leader-election-namespace=kube-system
        name: cert-manager
        volumeMounts:
        - name: ca-pemstore
          mountPath: /etc/ssl/certs/internal-ca.pem
          subPath: internal-ca.pem
          readOnly: false
        resources: {}
      volumes:
        - name: ca-pemstore
          configMap:
            # Provide the name of the ConfigMap containing the files you want
            # to add to the container
            name: ca-pemstore
`}</code></pre>
    <p>{`Apply the patch:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl patch deployment cert-manager -n cert-manager --patch "$(cat cm-ca-patch.yaml)"
`}</code></pre>
    <p>{`Cert-manager is now configured to trust your ACME CA.`}</p>
    <h3>{`3. Create a GCP service account and import its credentials`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Not using Google Cloud Platform?`}</strong>{` You can skip this step and configure the cert-manager `}<inlineCode parentName="p">{`Issuer`}</inlineCode>{` in step 4 to use a different challenge solver.
See cert-manager's documentation for `}<a parentName="p" {...{
          "href": "https://cert-manager.io/docs/configuration/acme/http01/"
        }}><inlineCode parentName="a">{`http-01`}</inlineCode></a>{` and `}<a parentName="p" {...{
          "href": "https://cert-manager.io/docs/configuration/acme/dns01/"
        }}><inlineCode parentName="a">{`dns-01`}</inlineCode></a>{` solvers.`}</p>
    </blockquote>
    <p>{`We're going to have cert-manager solve `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` ACME challenges.
So, it will need to be able to manage DNS entries.`}</p>
    <p>{`Let's create a Google Cloud Platform service account with the `}<inlineCode parentName="p">{`roles/dns.admin`}</inlineCode>{` role. Replace the `}<inlineCode parentName="p">{`PROJECT_ID`}</inlineCode>{` here with your own:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ export PROJECT_ID=step-cas-test
$ gcloud iam service-accounts create dns01-solver \\
   --project $PROJECT_ID --display-name "dns01-solver"
$ gcloud projects add-iam-policy-binding $PROJECT_ID \\
   --member serviceAccount:dns01-solver@$PROJECT_ID.iam.gserviceaccount.com \\
   --role roles/dns.admin
`}</code></pre>
    <p>{`Now import the service account's credentials as a Kubernetes secret:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ gcloud iam service-accounts keys create key.json \\
   --iam-account dns01-solver@$PROJECT_ID.iam.gserviceaccount.com
$ kubectl create secret generic clouddns-dns01-solver-svc-acct \\
   --from-file=key.json
`}</code></pre>
    <h3>{`4. Create the cert-manager Issuer`}</h3>
    <p>{`Finally, let's create an cert-manager Issuer to perform `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` ACME challenges. Make a new file called `}<inlineCode parentName="p">{`acme-issuer.yaml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: cert-manager.io/v1
kind: Issuer
metadata:
  name: acme-issuer
spec:
  acme:
    email: carl@smallstep.com
    server: https://ca.smallstep.internal/acme/acme/directory
    privateKeySecretRef:
      name: acme-issuer-account-key
    solvers:
    - dns01:
        cloudDNS:
          # Your Google Cloud Platform project ID:
          project: step-cas-test
          # Your Google CloudDNS zone name we will use for DNS01 challenges:
          hostedZoneName: step-cas-internal
          serviceAccountSecretRef:
            name: clouddns-dns01-solver-svc-acct
            key: key.json
`}</code></pre>
    <p>{`Replace the values for `}<inlineCode parentName="p">{`email`}</inlineCode>{`, `}<inlineCode parentName="p">{`server`}</inlineCode>{` URL, `}<inlineCode parentName="p">{`project`}</inlineCode>{` and `}<inlineCode parentName="p">{`hostedZoneName`}</inlineCode>{` with your own. Your Smallstep ACME endpoint will always take the form of `}<inlineCode parentName="p">{`https://[your CA hostname]/acme/acme/directory`}</inlineCode>{`.`}</p>
    <p>{`Apply it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl apply -f acme-issuer.yaml
`}</code></pre>
    <p>{`You now have an automated ACME certificate manager running inside your Kubernetes cluster.`}</p>
    <h3>{`5. Issue a test certificate`}</h3>
    <p>{`Let's get a test certificate from our ACME CA, using a Certificate object. Create a file called `}<inlineCode parentName="p">{`tls-certificate.yaml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: cert-manager.io/v1
kind: Certificate
metadata:
  name: k8s-internal
  namespace: default
spec:
  secretName: k8s-internal-tls
  issuerRef:
    name: acme-issuer
  dnsNames:
  - k8s.smallstep.internal
`}</code></pre>
    <p>{`Replace the `}<inlineCode parentName="p">{`dnsNames`}</inlineCode>{` value with a DNS name that's inside your zone.`}</p>
    <p>{`Apply it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl apply -f tls-certificate.yaml
`}</code></pre>
    <p>{`You can check the status with `}<inlineCode parentName="p">{`kubectl get certificaterequest`}</inlineCode>{` or `}<inlineCode parentName="p">{`kubectl describe certificate`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl get certificaterequest
NAME                    READY   AGE
k8s-internal-nzbnm      True    7s
$ kubectl describe certificate k8s-internal
Name:         k8s-internal
Namespace:    default
...
Kind:         Certificate
Metadata:
  Creation Timestamp:  2020-11-03T23:06:46Z
...
Spec:
  Dns Names:
    k8s.smallstep.internal
  Issuer Ref:
    Name:       acme-issuer
  Secret Name:  k8s-internal-tls
Status:
  Conditions:
    Last Transition Time:  2020-11-03T23:11:01Z
    Message:               Certificate is up to date and has not expired
    Reason:                Ready
    Status:                True
    Type:                  Ready
  Not After:               2020-11-04T23:11:01Z
  Not Before:              2020-11-03T23:11:01Z
  Renewal Time:            2020-11-04T15:11:01Z
  Revision:                1
Events:
  Type    Reason     Age    From          Message
  ----    ------     ----   ----          -------
  Normal  Issuing    10m    cert-manager  Issuing certificate as Secret does not exist
  Normal  Generated  10m    cert-manager  Stored new private key in temporary Secret resource "k8s-internal-g79jq"
  Normal  Requested  10m    cert-manager  Created new CertificateRequest resource "k8s-internal-nzbnm"
  Normal  Issuing    9m33s  cert-manager  The certificate has been successfully issued
`}</code></pre>
    <p>{`As you can see, cert-manager will automatically renew the certificate when approximately 2/3 of its lifetime has elapsed.`}</p>
    <p>{`That's it! You now have automated, short-lived certificates for your Kubernetes cluster. There are `}<a parentName="p" {...{
        "href": "https://cert-manager.io/docs/usage/"
      }}>{`many use cases`}</a>{` for X.509 certificates issued through cert-manager.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      